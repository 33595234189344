import { Row, Col, Typography } from 'antd'
import './Home.css'
import cali from '../assests/cali.jpg'
import engagement from '../assests/engagement.jpg'

const { Title } = Typography

const About = () => {
  return (
    <div id="home" style={{ minHeight: 750 }}>
      <Row>
        <Col xxl={3} xl={3} lg={3} xs={2} md={3} sm={3} />
        <Col xxl={8} xl={8} lg={18} xs={20} md={18} sm={18}>
          <div>
            <Title
              data-aos="zoom-out"
              data-aos-delay="200"
              data-aos-once
              style={{ fontWeight: 300, textAlign: 'left' }}
              className="title-tagline"
              level={1}
            >
              {' '}
              Hello there! <br /> I am{' '}
              <span style={{ fontWeight: 600 }}>Grace Barcus</span>{' '}
            </Title>
            <Title
              data-aos="zoom-out"
              data-aos-delay="500"
              data-aos-once
              level={3}
              style={{ fontWeight: 400, display: 'flex' }}
            >
              I am a masters student studying Nutrition at California
              Polytechnic University. Also, I have an undergraduate degree in
              Nutrition from California Polytechnic University.
            </Title>
            <Title
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              style={{ fontWeight: 300, textAlign: 'left', width: '100%' }}
              level={3}
            >
              I have a cattle dog named Cali who is the best. One of my favorite
              ways to relax is to take her to the beach.
            </Title>
          </div>
        </Col>
        <Col xxl={1} xl={1} lg={7} xs={2} />
        <Col xxl={0} xl={0} lg={0} xs={2} md={3} sm={3} />
        <Col xxl={9} xl={9} lg={9} xs={2} md={3} sm={3}>
          <div>
            <img
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              className="landing-img"
              alt="Cali"
              src={cali}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xxl={3} xl={3} lg={3} xs={2} md={3} sm={3} />
        <Col xxl={8} xl={8} lg={18} xs={20} md={18} sm={18}>
          <div>
            <br />
            <br />
            <br />
            <Title
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              style={{ fontWeight: 300, textAlign: 'left', width: '100%' }}
              level={3}
            >
              I am also engaged to one of the best men there is. We are excited
              to spend the rest of our lives together. Plus, he got me the BEST
              ring.
            </Title>
          </div>
        </Col>
        <Col xxl={1} xl={1} lg={7} xs={2} />
        <Col xxl={0} xl={0} lg={0} xs={2} md={3} sm={3} />
        <Col xxl={9} xl={9} lg={9} xs={2} md={3} sm={3}>
          <div>
            <img
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              className="landing-img"
              alt="Engagement"
              src={engagement}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default About
