import { Row, Col, Typography, Button } from 'antd'
import { AuditOutlined, LinkedinOutlined } from '@ant-design/icons'

const { Title } = Typography

const Contact = () => {
  return (
    <div id="contact" style={{ minHeight: 400 }}>
      <Row>
        <Col xxl={5} xl={5} lg={3} xs={2} md={3} sm={3} />
        <Col
          xxl={14}
          xl={14}
          lg={18}
          xs={20}
          md={18}
          sm={18}
          style={{ textAlign: 'center' }}
        >
          <div>
            <Title
              data-aos="zoom-out"
              data-aos-delay="200"
              data-aos-once
              level={1}
            >
              Let us get in touch...
            </Title>
            <Title
              data-aos="zoom-out"
              data-aos-delay="200"
              data-aos-once
              level={4}
            >
              You can reach out to me on any of these platforms!
            </Title>
          </div>
        </Col>
        <Col xxl={5} xl={5} lg={3} xs={2} md={3} sm={3} />
      </Row>
      <Row>
        <Col xxl={6} xl={6} lg={4} xs={3} md={3} sm={3} />
        <Col xxl={12} xl={12} lg={16} xs={18} md={18} sm={18}>
          <div>
            <br />
            <br />
            <div style={{ display: 'flex', marginLeft: '30%' }}>
              <Button
                data-aos="zoom-out"
                data-aos-delay="200"
                data-aos-once
                href="https://www.linkedin.com/in/grace-barcus-024794159/"
                style={{
                  backgroundColor: '#282a36',
                  borderWidth: 0,
                  marginRight: 25,
                  marginLeft: 25,
                }}
                type="primary"
                icon={<LinkedinOutlined />}
              />
              <Title
                data-aos="zoom-out"
                data-aos-delay="200"
                data-aos-once
                level={4}
              >
                Linkedin
              </Title>
              <Button
                data-aos="zoom-out"
                data-aos-delay="200"
                data-aos-once
                href="mailto:gracebarcus@gmail.com"
                style={{
                  backgroundColor: '#282a36',
                  borderWidth: 0,
                  marginLeft: 25,
                  marginRight: 25,
                }}
                type="primary"
                icon={<AuditOutlined />}
              />
              <Title
                data-aos="zoom-out"
                data-aos-delay="200"
                data-aos-once
                level={4}
              >
                Email
              </Title>
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={6} lg={4} xs={3} md={3} sm={3} />
      </Row>
    </div>
  )
}

export default Contact
