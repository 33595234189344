import { useState, useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
import grace from '../assests/gracie.jpg'
import './Home.css'
import TextTransition, { presets } from 'react-text-transition'

const { Title } = Typography

enum TitleTypes {
  Student = 'Masters Student',
  Wife = 'Soon-to-be-Wife',
  Researcher = 'Researcher',
}

const Titles: TitleTypes[] = [
  TitleTypes.Student,
  TitleTypes.Researcher,
  TitleTypes.Wife,
]

const Home = () => {
  const [currentTitle, setCurrentTitle] = useState<TitleTypes>(
    TitleTypes.Student
  )
  const [idx, setIdx] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => setIdx(idx + 1), 2000)
    return () => clearTimeout(intervalId)
  }, [])

  useEffect(() => {
    setCurrentTitle(Titles[idx % (Titles.length - 1)])
  }, [idx])

  return (
    <div id="home" style={{ minHeight: 750 }}>
      <Row>
        <Col xxl={3} xl={3} lg={3} xs={2} md={3} sm={3} />
        <Col xxl={8} xl={8} lg={18} xs={20} md={18} sm={18}>
          <div>
            <Title
              data-aos="zoom-out"
              data-aos-delay="200"
              data-aos-once
              style={{ fontWeight: 300, textAlign: 'left' }}
              className="title-tagline"
              level={1}
            >
              {' '}
              Hello there! <br /> I am{' '}
              <span style={{ fontWeight: 600 }}>Grace Barcus</span>{' '}
            </Title>
            <Title
              data-aos="zoom-out"
              data-aos-delay="500"
              data-aos-once
              level={3}
              style={{ fontWeight: 400, display: 'flex' }}
            >
              <p>A</p>
              <TextTransition
                style={{ width: 500, textAlign: 'left', marginLeft: 8 }}
                text={currentTitle}
                springConfig={presets.molasses}
              />
            </Title>
            <Title
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              style={{ fontWeight: 300, textAlign: 'left', width: '100%' }}
              level={3}
            >
              I am a Nutrition Masters student at California Polytechnic
              University. I have a cute cattle dog and have been published
              twice.
            </Title>
          </div>
        </Col>
        <Col xxl={1} xl={1} lg={7} xs={2} />
        <Col xxl={0} xl={0} lg={0} xs={2} md={3} sm={3} />
        <Col xxl={9} xl={9} lg={9} xs={2} md={3} sm={3}>
          <div>
            <img
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-once
              className="landing-img"
              alt="gracie"
              src={grace}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Home
