import { Suspense, useState } from 'react'
import { Menu, Layout } from 'antd'
import {
  HomeOutlined,
  ContactsOutlined,
  IdcardOutlined,
} from '@ant-design/icons'
import Home from 'components/Home'
import { useTheme } from 'styled-components'
import Contact from 'components/Contact'
import About from 'components/About'

const { Header, Content, Footer } = Layout

enum SitePage {
  Home = 'home',
  About = 'about',
  Contact = 'contact',
}

const getSitePage = (page: SitePage) => {
  switch (page) {
    case SitePage.Contact:
      return <Contact />
    case SitePage.About:
      return <About />
    case SitePage.Home:
    default:
      return <Home />
  }
}

export default function App(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<SitePage>(SitePage.Home)

  const theme = useTheme()

  const buildOnClick = (page: SitePage) => {
    return () => {
      setCurrentPage(page)
    }
  }

  return (
    <Suspense fallback="loading">
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Menu theme={'dark'} selectedKeys={[currentPage]} mode="horizontal">
            <Menu.Item
              key={SitePage.Home}
              onClick={buildOnClick(SitePage.Home)}
              icon={<HomeOutlined />}
            >
              Home
            </Menu.Item>
            <Menu.Item
              key={SitePage.About}
              onClick={buildOnClick(SitePage.About)}
              icon={<IdcardOutlined />}
            >
              About
            </Menu.Item>
            <Menu.Item
              key={SitePage.Contact}
              onClick={buildOnClick(SitePage.Contact)}
              icon={<ContactsOutlined />}
            >
              Contact
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 100, marginBottom: 50 }}>
          {getSitePage(currentPage)}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Grace Barcus @2022 Created by her husband Clint Decker
        </Footer>
      </Layout>
    </Suspense>
  )
}
