import { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

interface AppThemeProps {
  children: ReactNode
}

const theme = {
  fg: 'palevioletred',
  bg: 'white',
}

export default function AppTheme({ children }: AppThemeProps): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
